export const apiUrl = "https://menu.doremolab.com/api/";

export const apiKey = "NruVLsskuuj8XigNVCra";

export const storageUrl = "https://menu.doremolab.com/storage/";

export const loginUrl = `${apiUrl}login`;
export const logOutUrl = `${apiUrl}logout`;

export const CategoriesUrl = `${apiUrl}app/categories`;

export const productsUrl = `${apiUrl}app/products`;
